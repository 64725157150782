import React from "react";
import PropTypes from "prop-types";
import { Grid, Link, Paper, Typography } from "@mui/material";
import Layout from "../components/layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";


const LicenseTemplate = ({ data }) => {
  const image = getImage(data.messagesJson.imageURL);
  return (
    <>
      <Layout
        title={data.messagesJson.title}
        pathname={data.messagesJson.path}
        image={image}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <Typography variant="h3" component="h1">
              {data.messagesJson.title}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3}>
              <GatsbyImage image={image} alt={data.messagesJson.altText} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" component="h2">
              License Information
            </Typography>
            {data.messagesJson.license.length === 0 && (
              <Typography variant="body1">
                Content and images created by grabbingasnack.com
              </Typography>
            )}
            {data.messagesJson.license.map((item) => {
              return (
                <Grid
                  container
                  component="dl" // mount a Definition List
                  spacing={2}
                >
                  <Grid item>
                    <Typography component="dt" variant="h6">
                      Image Source:
                    </Typography>
                    <Typography component="dd" variant="body2">
                      <Link href={item.source}>{item.source}</Link>
                    </Typography>
                    <Typography component="dt" variant="h6">
                      Author:
                    </Typography>
                    <Typography component="dd" variant="body2">
                      <Link href={item.author.link}>{item.author.name}</Link>
                    </Typography>
                    <Typography component="dt" variant="h6">
                      License:
                    </Typography>
                    <Typography component="dd" variant="body2">
                      {item.licenseType}
                    </Typography>
                    <Typography component="dt" variant="h6">
                      Changes Made (if any):
                    </Typography>
                    <Typography component="dd" variant="body2">
                      {item.changesMade}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};

LicenseTemplate.propTypes = {
  data: PropTypes.shape({
    messagesJson: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      altText: PropTypes.string.isRequired,
      tags: PropTypes.array,
      imageURL: PropTypes.shape({
        publicURL: PropTypes.string,
        childImageSharp: PropTypes.shape({
          gatsbyImageData: PropTypes.object,
        }),
      }),
      license: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string,
        source: PropTypes.string,
        changesMade: PropTypes.string,
        licenseType: PropTypes.string,
        author: PropTypes.shape({
          name: PropTypes.string,
          link: PropTypes.string,
        }),
      })),
    }),
  }),
};

export default LicenseTemplate;

export const query = graphql`
  query messageLicenseByPath($parentPath: String!) {
    messagesJson(path: { eq: $parentPath }) {
      id
      path
      title
      altText
      createdDate
      tags
      imageURL {
        publicURL
        name
        extension
        childImageSharp {
          gatsbyImageData
        }
      }
      license {
        description
        author {
          name
          link
        }
        source
        changesMade
        licenseType
      }
    }
  }
`;
